import { AssaiaUser } from "@frontend/assaia-ui";
import { TDashboardConfig } from "@frontend/configuration";
import { OpenIdConfig } from "@models/common";

export class InitialApi {
  getInitialOpenIdCreds = async (): Promise<OpenIdConfig> => {
    const response = await fetch("/init.json");

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  getConfig = async (token: string): Promise<TDashboardConfig> => {
    const response = await fetch(
      `configuration/config.json?${new URLSearchParams({ token }).toString()}`
    );

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  getImgToken = async (user: AssaiaUser): Promise<string> => {
    const response = await fetch("/api/account/token", {
      headers: {
        Authorization: "JWT " + user.id_token,
      },
    });

    const { token } = await response.json();

    return token;
  };
}
