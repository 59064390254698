import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import { Detection } from "@models/detection";
import { toMilliSeconds, toMilliSecondsOrNull } from "@services/time";

export function parseBBoxes(
  data: any,
  detectionStart: number | null = null,
  detectionEnd: number | null = null
): Detection["bboxRanges"] {
  const result: Detection["bboxRanges"] = {};

  if (!data) {
    return result;
  }

  Object.entries(data).forEach(([camera, ranges]: any) => {
    result[camera] = ranges.map((r: any) => {
      const startTs = r.start_ts
        ? Math.max(toMilliSeconds(r.start_ts), detectionStart || 0)
        : detectionStart || 0;
      let endTs: number | null = null;
      if (!detectionEnd) {
        endTs = toMilliSecondsOrNull(r.end_ts);
      } else {
        if (!r.end_ts) {
          endTs = detectionEnd;
        } else {
          endTs = Math.min(toMilliSeconds(r.end_ts), detectionEnd);
        }
      }

      return {
        startTs,
        endTs,
        box: r.box,
        hires: r.hires.map((h: any) => ({
          url: h.url,
          ts: toMilliSecondsOrNull(h.ts),
        })),
        id: r.bbox_id,
      };
    });
  });

  const allRanges = Object.values(result).flat();

  if (detectionStart && !allRanges.some((r) => r.startTs === detectionStart)) {
    const box = minBy(allRanges, "startTs");
    if (box) {
      box.startTs = detectionStart;
    }
  }

  if (detectionEnd && !allRanges.some((r) => r.endTs === detectionEnd)) {
    const box = maxBy(allRanges, "endTs") || allRanges.find((r) => !r.endTs);
    if (box) {
      box.endTs = detectionEnd;
    }
  }

  return result;
}
