import { TAirportConfig } from "@frontend/configuration";
import {
  readFromLocalStorage,
  removeFromLocalStorage,
  writeToLocalStorage,
} from "./data";

const OVERRIDES_PATH = "configOverrides";
const IS_DEBUG_STORAGE_KEY = "configOverridesIsDebug";

export class LocalConfigOverrider {
  overrides: Partial<TAirportConfig> = {};
  isDebug = false;

  constructor() {
    this.overrides = readFromLocalStorage(OVERRIDES_PATH, this.overrides).value;
    this.isDebug = readFromLocalStorage(
      IS_DEBUG_STORAGE_KEY,
      this.isDebug
    ).value;

    window.configManager = this;
  }

  toggleIsDebug(v: boolean) {
    writeToLocalStorage(IS_DEBUG_STORAGE_KEY, v);
  }

  set(key: keyof TAirportConfig, value: unknown) {
    if (value === null || typeof value === "undefined") {
      delete this.overrides[key];
    } else {
      (this.overrides[key] as unknown) = value;
    }

    writeToLocalStorage(OVERRIDES_PATH, this.overrides);
    window.location.reload();
  }

  display() {
    console.log(this.overrides);
  }

  reset() {
    removeFromLocalStorage(OVERRIDES_PATH);
    window.location.reload();
  }
}
