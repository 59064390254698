import { TurnaroundParameterKey } from "@frontend/configuration";
import { components } from "@api/apiSpec";
import { KeysOf } from "@services/type-utils";

// TODO i dont like union, intersection seems better
export type TurnaroundParamName =
  | NonNullable<
      KeysOf<
        components["schemas"]["backend__v2__stands__schemas__TurnaroundParams"]
      >
    >
  | TurnaroundParameterKey;

export type TurnaroundParams = Record<TurnaroundParamName, number | null> & {
  [PARAMS_NULLED_SYMBOL]?: TurnaroundParamName[];
};
export const PARAMS_NULLED_SYMBOL = Symbol(
  "Some turnaround params was nulled on client side"
);
