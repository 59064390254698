import { TAirportConfig } from "@frontend/configuration";
import { PARAMS_NULLED_SYMBOL } from "@models/TurnaroundParams";
import { typedEntries } from "@services/data";
import { cloneDeep } from "lodash";

export const prepareTurnaroundParams = (
  targetParams: any,
  turnParamsSource: TAirportConfig["turnParamsSource"]
) => {
  if (!targetParams) {
    targetParams = {};
  }

  const result = cloneDeep(targetParams);

  const turnParamsSourceEntries = typedEntries(turnParamsSource);
  if (turnParamsSourceEntries.length) {
    turnParamsSourceEntries.forEach(([flightStatusKey]) => {
      result[flightStatusKey] = null;
    });
    result[PARAMS_NULLED_SYMBOL] = turnParamsSourceEntries.map((v) => v[0]);
  }

  return result;
};
