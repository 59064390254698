import { defineMessage } from "react-intl";

export const TIME_ZONE_ID_KEY = "TIME_ZONE_ID_KEY";

export const EMPTY_APRON_LABEL = defineMessage({
  defaultMessage: "No active turnaround",
  description: "Strings constants: EMPTY_APRON_LABEL",
});

export const NOT_AVAILABLE = defineMessage({
  defaultMessage: "N/A",
  description: "[ignore] Strings constants: N/A",
});

export const MODAL_TS_QUERY_KEY = "ts";
export const MODAL_DETECTION_ID_QUERY_KEY = "detection_id";
export const MODAL_SAFETY_ID_QUERY_KEY = "safety_label";

export const AIRCRAFT_ON_STAND = "aircraft_on_stand";

export const MAP_POSITION_STORAGE_KEY = "MAP_POSITION";

export const MARKED_ALERTS_STORAGE_KEY = "MARKED_ALERTS";

export const FIRST_LOGIN_TOUR_STORAGE_KEY = "FIRST_LOGIN_TOUR";

export const OS_NOTIFICATIONS_PROMPT_SHOWN_STORAGE_KEY =
  "OS_NOTIFICATIONS_PROMPT_SHOWN_STORAGE_KEY";

export const TURN_STATE = {
  PRE_AIRCRAFT: "pre_aircraft",
  AIRCRAFT_ENTERS_STAND: "aircraft_enters",
  AIRCRAFT_ON_STAND: "aircraft",
  POST_AIRCRAFT: "post_aircraft",
  FINISHED: "finished",
} as const;

export const SAFETY_PATH = "safety";

export const SHOW_DEPARTURE_QUEUE_STORAGE_KEY =
  "SHOW_DEPARTURE_QUEUE_STORAGE_KEY";

export const SHOW_ALERT_BAR_STORAGE_KEY = "SHOW_ALERT_BAR_STORAGE_KEY";

export const IS_TC2_TRIAL_ACTIVE_STORAGE_KEY =
  "IS_TC2_TRIAL_ACTIVE_STORAGE_KEY";

export const IS_WELCOME_MODAL_ALREADY_SHOWN_STORAGE_KEY =
  "IS_WELCOME_MODAL_ALREADY_SHOWN_STORAGE_KEY";
